





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class MobilityConfigure extends Vue {
  @Prop() public id!: number;

  public $route: any;
  public $router: any;
  public $q: any;
  public $store: any;

  public loading: boolean = false;
  private analyzers: any = [];

  public async created() {
    this.refreshAnalyzers();
  }

  private refreshAnalyzers() {
    this.$store.dispatch('loadAnalyzers', {
      params: {
        outdoor: true,
      },
    })
    .then((response: any) => {
      this.loading = false;
      this.analyzers = response;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private goto(analyzerId: number) {
    this.$router.push({ path: `/mobility/configure/${analyzerId}` });
  }
}
