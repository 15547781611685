import { render, staticRenderFns } from "./MobilityConfigure.vue?vue&type=template&id=611e560a&scoped=true&"
import script from "./MobilityConfigure.vue?vue&type=script&lang=ts&"
export * from "./MobilityConfigure.vue?vue&type=script&lang=ts&"
import style0 from "./MobilityConfigure.vue?vue&type=style&index=0&id=611e560a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "611e560a",
  null
  
)

/* custom blocks */
import block0 from "./MobilityConfigure.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QMarkupTable,QInnerLoading,QSpinner});
